var render = function () {
  var _vm$employee, _vm$employee2, _vm$employee3, _vm$selectedCategory;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "EmployeeDetails"
  }, [_c('BackArrow', {
    attrs: {
      "variant": "black",
      "to": '/savings-calculator'
    }
  }), _c('div', {
    staticClass: "PageHeader"
  }, [_c('div', [_c('Headline', {
    attrs: {
      "title": ""
    }
  }), _c('h2', {
    staticClass: "EmployeeName"
  }, [_vm._v(_vm._s((_vm$employee = _vm.employee) === null || _vm$employee === void 0 ? void 0 : _vm$employee.name))])], 1), _c('div', [_c('div', {
    staticClass: "ActionButtons"
  }, [_c('Button', {
    attrs: {
      "variant": "basic",
      "size": "small"
    },
    on: {
      "onClick": _vm.showSettingsModal
    }
  }, [_vm._v(" Einstellung ")]), _c('Button', {
    attrs: {
      "variant": "basic",
      "size": "small"
    },
    on: {
      "onClick": _vm.showNewEntryModal
    }
  }, [_vm._v(" Ausgabe Eintragen ")]), _c('Button', {
    attrs: {
      "variant": "basic",
      "size": "small"
    },
    on: {
      "onClick": _vm.showNewEntryModal
    }
  }, [_vm._v(" Einnahme Eintragen ")]), _c('Button', {
    attrs: {
      "variant": "",
      "size": "small"
    },
    on: {
      "onClick": _vm.showSavingsEntryModal
    }
  }, [_vm._v(" Rücklage Bilden ")])], 1)])]), _c('div', {
    staticClass: "OverviewCards"
  }, [_c('Card', [_c('h4', [_vm._v("Umsatz")]), _c('h2', [_vm._v(_vm._s(_vm.formatEuro((_vm$employee2 = _vm.employee) === null || _vm$employee2 === void 0 ? void 0 : _vm$employee2.income)))])]), _c('Card', [_c('h4', [_vm._v("Bar (39%)")]), _c('h2', [_vm._v(_vm._s(_vm.formatEuro((_vm$employee3 = _vm.employee) === null || _vm$employee3 === void 0 ? void 0 : _vm$employee3.income)))])]), _c('Card', [_c('h4', [_vm._v("Bargeldlose (71%)")]), _c('h2', [_vm._v(" " + _vm._s(_vm.formatEuro(_vm.profit)) + " ")]), _c('span', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: {
        content: _vm.creditCardInfo,
        html: true,
        placement: 'top'
      },
      expression: "{ content: creditCardInfo, html: true, placement: 'top' }"
    }],
    staticClass: "info-icon"
  }, [_vm._v(" ⓘ ")])]), _c('Card', [_c('h4', [_vm._v("Ausgaben")]), _c('h2', {
    class: {
      'text-negative': _vm.totalExpenseAmount < 0
    }
  }, [_vm._v(" " + _vm._s(_vm.formatEuro(_vm.totalExpenseAmount)) + " ")])]), _c('Card', [_c('h4', [_vm._v("Zwischensumme")]), _c('h2', {
    class: {
      'text-positive': _vm.profit > 0
    }
  }, [_vm._v(" " + _vm._s(_vm.formatEuro(_vm.profit)) + " ")])]), _c('Card', [_c('h4', [_vm._v("Rücklage (47%)")]), _c('h2', [_vm._v(_vm._s(_vm.formatEuro(_vm.share)))])]), _c('Card', [_c('h4', [_vm._v("Ergenis")]), _c('h2', [_vm._v(_vm._s(_vm.formatEuro(_vm.open)))])])], 1), _c('div', {
    staticClass: "ChartButtons"
  }, [_c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Ausgaben in Barchart anzeigen",
      expression: "`Ausgaben in Barchart anzeigen`"
    }],
    staticClass: "IconButton",
    class: {
      isActive: _vm.chartType === 'bar'
    },
    on: {
      "click": function click($event) {
        _vm.chartType = 'bar';
      }
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "d": "M3 12H7V21H3V12ZM17 8H21V21H17V8ZM10 2H14V21H10V2Z"
    }
  })])]), _c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Ausgaben in Piechart anzeigen",
      expression: "`Ausgaben in Piechart anzeigen`"
    }],
    staticClass: "IconButton",
    class: {
      isActive: _vm.chartType === 'pie'
    },
    on: {
      "click": function click($event) {
        _vm.chartType = 'pie';
      }
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "d": "M11 2.04938V13H21.9506C21.4489 18.0533 17.1853 22 12 22C6.47715 22 2 17.5229 2 12C2 6.81465 5.94668 2.5511 11 2.04938ZM13 2.04938C17.7244 2.51845 21.4816 6.27559 21.9506 11H13V2.04938Z"
    }
  })])])]), _vm.chartData.length ? _c('ChartV2', {
    attrs: {
      "groupByService": "",
      "renderSeparately": "",
      "direction": "horizontal",
      "type": _vm.chartType,
      "secondValues": _vm.chartComparisonData,
      "title": _vm.chartTitle,
      "secondValuesTitle": _vm.chartComparisonTitle,
      "values": _vm.chartData,
      "serviceColors": _vm.serviceColors,
      "activeBar": _vm.activeBar
    }
  }) : _vm._e(), _c('div', {
    staticClass: "FilterButtons"
  }, _vm._l(_vm.categories.filter(function (c) {
    return !c.inFilterHidden;
  }), function (category) {
    return _c('button', {
      key: category.id,
      staticClass: "FilterButton",
      class: {
        isActive: _vm.selectedFilter.id === category.id
      },
      on: {
        "click": function click($event) {
          return _vm.handleFilterSelect(category);
        }
      }
    }, [_vm._v(" " + _vm._s(category.value) + " ")]);
  }), 0), _c('div', {
    staticClass: "ExpensesAndRevenueTableSection"
  }, [_c('div', {
    staticClass: "RevenueTableSection"
  }, [_c('h3', [_vm._v("Einnahmen")]), _c('ExpensesTable', {
    attrs: {
      "expenses": _vm.filteredRevenue
    },
    on: {
      "onClick": _vm.handleRowClick
    }
  })], 1), _c('div', {
    staticClass: "ExpensesTableSection"
  }, [_c('h3', [_vm._v("Ausgaben")]), _c('ExpensesTable', {
    attrs: {
      "expenses": _vm.filteredExpenses
    },
    on: {
      "onClick": _vm.handleRowClick
    }
  })], 1)]), _c('Modal', {
    attrs: {
      "title": 'Einstellung',
      "show": _vm.settingsModal.active,
      "action": {
        text: 'Speichern',
        color: 'dark',
        callback: _vm.saveSettings
      },
      "cancel": {
        text: 'Abbrechen'
      }
    },
    on: {
      "onModalClose": _vm.handleSettingsModalClose
    }
  }, [_c('p', [_vm._v("Tragen Sie hier den Anteil ein, der von den Rücklagen übernommen wird.")]), _c('div', {
    staticClass: "SettingsInputs"
  }, _vm._l(_vm.categories.filter(function (c) {
    return !c.hidden;
  }), function (setting) {
    return _c('div', {
      key: setting.id,
      staticClass: "ServiceInput"
    }, [_c('label', [_vm._v(_vm._s(setting.value))]), _c('Input', {
      attrs: {
        "type": "text",
        "pattern": "[0-9]+([,][0-9]+)?",
        "placeholder": "0%"
      },
      model: {
        value: setting.percent,
        callback: function callback($$v) {
          _vm.$set(setting, "percent", $$v);
        },
        expression: "setting.percent"
      }
    })], 1);
  }), 0)]), _c('Modal', {
    attrs: {
      "title": 'Ausgabe Eintragen',
      "show": _vm.expenseModal.active,
      "action": {
        text: 'Speichern',
        color: 'dark',
        callback: _vm.saveExpense
      },
      "cancel": {
        text: 'Abbrechen'
      }
    },
    on: {
      "onModalClose": _vm.handleExpenseModalClose
    }
  }, [_c('div', [_c('p', [_vm._v("Tragen Sie eine Ausgabe des Fahrers ein.")]), _c('br'), _c('div', [_c('label', {
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "for": "category"
    }
  }, [_vm._v("Kategorie")]), _c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "items": _vm.categories,
      "selected": _vm.selectedCategory.value,
      "variant": "single-column",
      "placeholder": "Kategorie auswählen"
    },
    on: {
      "onItemSelect": _vm.handleCategorySelect
    }
  }), _c('br'), ((_vm$selectedCategory = _vm.selectedCategory) === null || _vm$selectedCategory === void 0 ? void 0 : _vm$selectedCategory.id) === 'custom' ? _c('Input', {
    attrs: {
      "type": "text",
      "id": "category",
      "name": "category",
      "keyName": "category",
      "placeholder": "Eigene Kategorie",
      "value": _vm.expenseModal.category
    },
    on: {
      "onKeyPress": _vm.handleCustomCategoryChange
    }
  }) : _vm._e()], 1), _c('br'), _c('Input', {
    attrs: {
      "value": _vm.expenseModal.date,
      "label": "Datum der Ausgabe",
      "type": "date",
      "placeholder": "Datum auswählen",
      "direction": "vertical"
    },
    on: {
      "onKeyPress": _vm.handleDateChange
    }
  }), _c('br'), _c('div', [_c('label', {
    attrs: {
      "for": "amount"
    }
  }, [_vm._v("Betrag")]), _c('Input', {
    attrs: {
      "type": "text",
      "id": "amount",
      "name": "amount",
      "keyName": "amount",
      "placeholder": "40,50",
      "value": _vm.expenseModal.amount
    },
    on: {
      "onKeyPress": _vm.handleAmountChange
    }
  })], 1)], 1)]), _c('Modal', {
    attrs: {
      "title": 'Rücklage Bilden',
      "show": _vm.savingsEntryModal.active,
      "action": {
        text: 'Speichern',
        color: 'dark',
        callback: _vm.saveSavingsEntry
      },
      "cancel": {
        text: 'Abbrechen'
      }
    },
    on: {
      "onModalClose": _vm.handleSavingsEntryModalClose
    }
  }, [_c('div', [_c('p', [_vm._v("Tragen Sie hier die Rücklage ein.")]), _c('br'), _c('Input', {
    attrs: {
      "value": _vm.savingsEntryModal.date,
      "label": "Datum",
      "type": "date",
      "placeholder": "Datum auswählen"
    },
    on: {
      "onKeyPress": _vm.handleSavingsEntryDateChange
    }
  }), _c('br'), _c('div', [_c('label', {
    attrs: {
      "for": "savingsAmount"
    }
  }, [_vm._v("Betrag (max: " + _vm._s(_vm.formatEuro(_vm.open)) + ")")]), _c('Input', {
    attrs: {
      "type": "number",
      "id": "savingsAmount",
      "name": "savingsAmount",
      "max": _vm.open,
      "placeholder": "Betrag eingeben",
      "value": _vm.savingsEntryModal.amount
    },
    on: {
      "onKeyPress": _vm.handleSavingsEntryAmountChange
    }
  })], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }